import React, { Component } from "react"
import ImagePicker from "../../components/ImagePicker"

import styled from "styled-components"
import ProductDescription from "./ProductDescription"
import IndexSection3 from "../IndexSection3"

const ProductLayout = styled.div`
  #products-layout {
    /* padding: 20px; */

    display: grid;
    grid-template-columns: 50% 50%;
    /* align-items: baseline; */

    @media (max-width: 380px) {
      grid-template-columns: 100%;
    }
  }
`

class Product extends Component {
  render() {
    return (
      <>
        <ProductLayout>
          <div id="products-layout">
            <ImagePicker />
            <ProductDescription />
          </div>
          <IndexSection3 />
        </ProductLayout>
      </>
    )
  }
}

export default Product
